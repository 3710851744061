.create-grid-header {
    margin-right: 24px;

    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-size: 36px;
    text-align: left;
    font-weight: bold;

    align-self: flex-end; 
}

div.mobile-read-only[contenteditable="true"],
div.mobile-clue-input[contenteditable="true"],
div.mobile-title-input[contenteditable="true"] {
    -webkit-user-modify: read-only;
}

.create-instruction-header {
    margin-right: 50px;
    margin-top: 20px;

    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-size: 36px;
    text-align: center;
    font-weight: bold;

    align-self: center; 
}

.create-instruction-subheading {
    margin-top: 20px;

    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-size: 26px;
    text-align: center;
    font-weight: normal;

    align-self: center; 
}

.mobile-create-instruction-subheading {
  margin-top: 5px;

  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-size: 16px;
  text-align: center;
  font-weight: normal;

  align-self: center; 
}


.create-subheading {
    margin-bottom: 15px;

    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-size: 28px;
    text-align: left;
    font-weight: bold;
}


.create-subheading-clues {
    margin-bottom: 12.5px;

    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-size: 28px;
    text-align: left;
    font-weight: bold;
}

.create-subheading-title {
    margin-bottom: 12.5px;
    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-size: 28px;
    text-align: left;
    font-weight: bold;
}

.create-grid-container {
    margin-left: 80px;
    margin-top: 40px;
    margin-bottom: 15px;
}

.create-title-container {
    width: 672px;
    margin-top: 5px;
    margin-left: 30px;
}

.create-button-container {
    margin-top: 22px;
}

.create-container {
    display: flex;
    gap: 30px;
}

.word-hint-container {
    display: flex;
    margin-top: 65px;
    margin-left: 30px;
    gap: 70px;
}

.word-hint-outer-container {
    width: 100%;
    display: column;
}

.create-submit-button {
    border: 1.5px solid #2D2D2D;
    transition: background-color 0.3s, color 0.3s; 

    width: 200px;
    height: 50px;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    color: white;
    background-color: #2D2D2D;
    cursor: pointer;
  }

.create-submit-cw-button {
    border: 1.5px solid #2D2D2D;
    transition: background-color 0.3s, color 0.3s; 

    margin-top: 15px;
    margin-left: 30px;

    width: 200px;
    height: 50px;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    color: white;
    background-color: #2D2D2D;
    cursor: pointer;
  }

.create-edit-button {
    border: 1.5px solid #2D2D2D;
    transition: background-color 0.3s, color 0.3s; 

    width: 200px;
    height: 50px;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    color: white;
    background-color: #2D2D2D;
    cursor: pointer;
    margin-left: 13px;
  }

.create-edit-button:hover {
    border:none;

    background-color: var(--color-dark); 
    color: white;
}

.create-submit-button:hover {
    border: none;

    background-color: var(--color-dark); 
    color: white;
}

.create-submit-cw-button:hover {
    border:none;

    background-color: var(--color-dark); 
    color: white;
}

.create-back-button {
    transition: background-color 0.3s, color 0.3s; 

    padding-left: 0px !important;
    margin-left: 0px;

    background: none;
    border: none;
    color: var(--color-normal);
    font: inherit; 
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin-right: 25px;
    margin-top: 15px;
    transition: background-color 0.3s, color 0.3s;
    font-size: 35px;
    align-items: center;
    align-self: center;

    margin-bottom: 3px;
    padding-bottom: 0px;
  }

.create-back-button:hover {
    color: var(--color-dark);
  }

.create-reset-button {
    transition: background-color 0.3s, color 0.3s; 

    background: none;
    border: none;
    color: var(--color-normal);
    font: inherit; 
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin-right: 0px;
    margin-top: 15px;
    transition: background-color 0.3s, color 0.3s;
    font-size: 35px;
    align-items: center;
    align-self: center;

    margin-bottom: 3px;
    padding-bottom: 0px;
  }

.create-reset-button:hover {
    color: var(--color-dark);
  }

.create-back-header {
    width: 575px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

.create-words ul {
    list-style-type: none;
    padding: 0; 
    margin: 0; 
    }

.create-words li {
    font-family: 'Inter', sans-serif; 
    font-size: 18px;
    color: #2D2D2D; 
    margin-bottom: 20px !important; 
    }

.create-words li:last-child {
    margin-bottom: 0 !important;
}

.create-words ul {
    list-style-type: none; 
    padding: 0; 
    margin: 0; 
    }
    
.word-number {
    font-weight: bold; 
    text-align: left;
    font-size: 22px;
    }

.word-text {
    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-size: 22px;
    list-style-type: none; 
    padding-left: 0;
    align-items: center;
    }

.clue-input {
    padding: 5px; 
    width: 443px;
    margin-bottom: 2px;
    padding-right: 35px;
    font-size: 20px; 
    border: 1px solid #ccc; 
}

.clue-input:focus {
    border-color: var(--color-normal); 
    outline: none;
    }
    
.clue-input:hover {
    background-color: #f0f0f0;
    }

.title-input {
    padding: 5px; 
    margin-bottom: 10px;
    width: 658px;
    padding-right: 35px;
    font-size: 20px; 
    border: 1px solid #ccc; 
}

.title-input:focus {
    border-color: var(--color-normal); 
    outline: none;
    }

.title-input:hover {
    background-color: #f0f0f0;
    }   


.create-clues {
    display: flex;
    flex-direction: column;
}

.create-words ul, .create-clues ul {
    list-style-type: none; 
    padding: 0;
    margin: 0; 
    }
    
.create-words li, .create-clues li {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: #2D2D2D;
    margin-bottom: 10px; 
    }

.across-clues {
    margin-bottom: 55px !important;
    }

    .create-instruction-container {
        display: flex;
        flex-direction: column;
        align-items: center; 
        justify-content: center; 
        padding: 20px;
      }
      
      .word-values-grid {
        align-self: center;
        display: grid;
        grid-template-columns: repeat(auto-fill, 88px);
        gap: 0px;
        margin-top: 140px;
        margin-left: 140px;
        width: 60%;
        max-width: 700px;
        overflow-x: auto;
      }
    
      
.display-word-cell {
    background-color: var(--color-light);
    width: 85px;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #2D2D2D;
    font-size: 35px;
    font-family: 'Inter', sans-serif;
    caret-color: transparent;    
}

.word-grid-container {
    display: grid;
    margin-right: 54px;
    margin-top: 20px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    padding: 20px;
    max-height: 400px; /* Adjust this value based on your preference */
    overflow-y: auto; /* Enables vertical scrolling */
  }

  .word-cell {
    padding: 10px;
    border: 1.5px solid #2D2D2D;
    text-align: center;
    font-family: 'Inter', sans-serif;
    background-color: #f9f9f9; 
  }

  .word-cell:hover {
    background-color: var(--color-light);
    cursor: pointer;
  }
  
  .no-word-warn {
    margin-left: 200px;
    width: 300px;
    font-size: 24px;
    margin-bottom: 40px;
    font-weight: bold;
    padding: 5px;
    border: 1.5px solid #2D2D2D;
    text-align: center;
    font-family: 'Inter', sans-serif;
    background-color: #f9f9f9; 
  }

  .loading-words {
    font-family: 'Inter', sans-serif;
    margin-left: 330px;
    margin-top: 40px;
    font-size: 14px;
    font-weight: bold;
  }

  .error-icon {
    font-size: 18px;
    position: absolute;
    right: 10px;
    top: 52%;
    transform: translateY(-50%);
    color: var(--color-dark);
  }

  .title-error-icon {
    font-size: 18px;
    position: absolute;
    right: 10px;
    top: 44%;
    transform: translateY(-50%);
    color: var(--color-dark);
  }
  
  .error-message {
    visibility: hidden;
    color: white;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    width: 140px;
    background-color: var(--color-dark);
    text-align: left;
    border-radius: 5px;
    padding: 0px;
    padding-left: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -100px; 
  }

  .mobile-error-message {
    visibility: hidden;
    color: white;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    width: 140px;
    background-color: var(--color-dark);
    text-align: left;
    border-radius: 5px;
    padding: 0px;
    padding-left: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -100px; 
  }
  
  .error-icon:hover .error-message {
    visibility: visible;
  }

  .title-error-icon:hover .error-message {
    visibility: visible;
  }

  .hint-input-container {
    position: relative;
    display: flex;
    align-items: center;
  }

  .title-input-container {
    position: relative;
    display: flex;
    align-items: center;
  }

  .across-words-container {
    margin-bottom: 20px;
  }


  /* MOBILE */
  .mobile-create-header {
    font-family: 'Inter', sans-serif;
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: space-between;
}

.mobile-create-header h1 {
  margin: 0px;
  padding: 0px;
  margin-top: 12.5px;
  font-size: 26px;
  font-style: italic;

  display: flex;
}

.mobile-create-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
}

.mobile-grid-container {
  margin-top: 7px;
  margin-left: 20px;
  max-height: 370px;
}

.button-text {
  font-size: 20px;
  margin-right: 3px;
  font-style: italic;
}

.mobile-word-values-grid {
  display: flex;
  align-self: center;
  gap: 3px;
  width: 100%;
  overflow-x: auto;
}

.mobile-display-word-cell {
  background-color: var(--color-light);
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #2D2D2D;
  font-size: 35px;
  font-family: 'Inter', sans-serif;
  caret-color: transparent;    
}

.mobile-word-values-container {
  width: fit-content;
}

.mobile-word-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 3fr));
  gap: 4px;

  padding-right: 20px !important;
  padding-left: 35px;

  max-height: 400px;

  margin-top: 25px;
  padding-top: 0px;
  margin-bottom:130px;
  padding-bottom: 230px;
  margin-left: 5px;
  
  width: 37vh;
  overflow-y: auto; 
  overflow-x: hidden;
}

.mobile-word-cell {
  width: 5vh;
  padding: 10px;
  border: 1.5px solid #2D2D2D;
  text-align: center;
  font-family: 'Inter', sans-serif;
  background-color: #f9f9f9; 
}

.mobile-no-word-warn {
  margin-top: 23px;
  position: absolute; 
  height: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
  border: 1.5px solid #2D2D2D;
  text-align: center;
  font-family: 'Inter', sans-serif;
  background-color: #f9f9f9;
}

.mobile-warning-container {
  display: flex;
  justify-content: center;
}

.mobile-loading-words {
  margin-top: 20px;
  position: absolute; 
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: bold;
}

@media (min-width: 421px) {
  .grid {
    transform: scale(1);
  }
}

@media (max-width: 720px) {
  .grid {
    transform: scale(0.65);
    margin-left: 19px;
  }
}

@media (min-width: 421px) {
  .grid {
    transform: scale(1);
  }
  .mobile-grid-container {
    max-height: 400px;
  }
}
  
  @media (max-width: 720px) {
    .grid {
      transform: scale(0.695);
      margin-left: 0px;
    }
    .mobile-grid-container {
      max-height: 380px;
    }
  }

@media (max-width: 400px) {
  .grid {
    transform: scale(0.644);
    margin-left: 0px;
  }
  .mobile-grid-container {
    max-height: 340px;
  }
}

@media (max-width: 380px) {
  .grid {
    transform: scale(0.59);
    margin-left: 8px;
  }  
  .mobile-grid-container {
    max-height: 310px;
  }

}

@media (max-width: 300px) {
  .grid {
    transform: scale(0.48);
  }
}

.mobile-word-hint-container {
  margin-top: 7px;
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
  gap: 17px;
  padding-bottom: 0px;
}

.mobile-create-subheading {
  margin-bottom: 5px;

  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
}


.mobile-create-subheading-clues {
  margin-bottom: 5px;

  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
}

.mobile-create-subheading-title {
  width:200px;
  margin-bottom: 2px;
  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
}

.mobile-clue-input {
  padding: 2px; 
  width: 26.4vh;
  height: 23px;
  overflow-x: auto !important;
  font-size: 20px; 
  border: 1px solid #ccc; 
  border-radius: 0;
}

.mobile-clue-input:focus {
  border-color: var(--color-normal); 
  outline: none;
  }
  
.mobile-clue-input:hover {
  background-color: #f0f0f0;
  }

  .mobile-across-clues {
    margin-bottom: 28px !important;
    margin-top: 28px !important;
    }

    .mobile-across-clues li {
      margin-bottom: 6.6px;
      }

    .mobile-across-words-container {
      margin-bottom: 10px;
    }

    .mobile-create-words li {
      font-family: 'Inter', sans-serif; 
      font-size: 18px;
      color: #2D2D2D; 
      margin-bottom: 10px !important; 
      }

      .mobile-create-words ul {
        list-style-type: none;
        padding: 0; 
        margin: 0; 
        }
    
    .mobile-create-words li {
        font-family: 'Inter', sans-serif; 
        font-size: 18px;
        color: #2D2D2D; 
        margin-bottom: 11.4px !important; 
        }
    
    .mobile-create-words li:last-child {
        margin-bottom: 0 !important;
    }
    
    .mobile-create-words ul {
        list-style-type: none; 
        padding: 0; 
        margin: 0; 
        }

        .mobile-title-input {
          padding: 2px; 
          border-radius: 0px;
          margin-bottom: 14px;
          width: 100%;
          padding-right: 35px;
          font-size: 20px; 
          border: 1px solid #ccc; 
          overflow-x: auto;
      }
      
      .mobile-title-input:focus {
          border-color: var(--color-normal); 
          outline: none;
          }
      
      .mobile-title-input:hover {
          background-color: #f0f0f0;
          }  

          .mobile-title-input-container {
            position: relative;
            display: flex;
            align-items: center;    
            width: 39vh;      
          }
        
          .mobile-create-title-container {
            width:40%;
            margin-top: 5px;
            margin-left: 30px;
        }

        .mobile-word-hint-outer-container {
          width: 100%;
          height: fit-content;
          display: column;
          margin-right: 30px;
          margin-top: 20px;
          margin-bottom: 200px; /* decides how far you can scroll down */
      }

      .mobile-hint-input-container {
        position: relative;
        display: flex;
        align-items: center;
      }

      .mobile-create-clues {
        display: flex;
        flex-direction: column;
    }
    
    .mobile-create-words ul, .mobile-create-clues ul {
        list-style-type: none; 
        padding: 0;
        margin: 0; 
        }
        
    .mobile-create-words li, .mobile-create-clues li {
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        color: #2D2D2D;
        margin-bottom: 10px; 
        }

    .mobile-create-words ul {
      list-style-type: none;
      padding: 0; 
      margin: 0; 
      }
  
  .mobile-create-words li:last-child {
      margin-bottom: 0 !important;
  }
  
  .mobile-create-words ul {
      list-style-type: none; 
      padding: 0; 
      margin: 0; 
      }

    .mobile-word-number {
      font-weight: bold; 
      text-align: left;
      font-size: 20px;
      }
    
    .mobile-word-text {
        font-family: 'Inter', sans-serif;
        font-style: italic;
        font-size: 20px;
        list-style-type: none; 
        padding-left: 7px;
        align-items: center;
        }

  .mobile-down-clues li {
    margin-bottom: 8px;
  }

.mobile-create-submit-cw-button {
  border: none;
  transition: background-color 0.3s, color 0.3s; 
  width: 100%;
  height: 60px;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  color: white;
  background-color: var(--color-dark);
  cursor: pointer;
}

.mobile-edit-button {
  background: none;
  border: none;
  color: var(--color-normal);
  font: inherit;
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
  margin-top: 12.5px;
  margin-left: 15px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 26px;
  align-items: center;
  align-self: center;
  margin-right: 10px;
}

.mobile-create-back-button {
  background: none;
  border: none;
  color: var(--color-normal);
  font: inherit;
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
  margin-top: 12.5px;
  margin-right: 15px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 26px;
  align-items: center;
  align-self: center;
  margin-left: 10px;
}

.mobile-create-instruction-header {

    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-size: 12px;
    text-align: center;
    font-weight: bold;

    align-self: center; 
}

.mobile-create-instruction-container {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  padding: 20px;
}