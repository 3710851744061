.profile-container {
  max-width: 600px;
  margin-left: 60px;
  margin-top: 10px;
  padding: 20px;
  text-align: left;
  font-family: 'Inter', sans-serif;
}
  
.profile-container h1 {
  font-size: 50px;
  margin-bottom: 20px;
  margin-left: 20px;
  font-style: italic;
  max-width: 200px;           
  overflow-x: auto;       
  white-space: nowrap;   
  flex-shrink: 0; 
  min-width: 0;   
}

.profile-details {
  margin-left: 25px;
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr; 
  gap: 20px;
}

.puzzle-details {
  margin-left: 25px;
}

.profile-details strong {
  font-size: 22px;
  margin-bottom: 5px;
  font-weight: bold;
}

.back-profile-header {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center; 
  margin-bottom: 5px;
  margin-left: 406px;
}

.back-profile-header-notme {
  display: flex;
  align-items: center;
  justify-content: left; 
  margin-bottom: 5px;
}

.profile-back-button {
    background: none;
    border: none;
    color: var(--color-normal);
    font: inherit; 
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 0px;
    margin-right: 10px;
    margin-top: 15px;
    transition: background-color 0.3s, color 0.3s;
    font-size: 35px;
    align-items: center;
    align-self: center;
  }

  .profile-back-button:hover {
    color: var(--color-dark);
  }

.profile-item {
  display: flex;
  flex-direction: column; 
  font-size: 22px;
}

.puzzles-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 20px; 
}

.trait-header {
  margin-top: 43px;
  margin-bottom: 15px;
  font-size: 30px;
  font-weight: bold;
}

.puzzles-list ul {
  list-style: none;
  padding: 0;
  display: grid;
  margin: 0px;
  grid-template-columns: repeat(3, 1fr); 
  gap: 4px 100px; /* vertical, horizontal spacing. respectively */
}

.puzzles-list li {
  list-style-type: none;
  padding: 10px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.puzzles-list li strong {
  margin-bottom: 10px;
  text-align: center;
}

.profile-grid {
  display: flex;
  flex-direction: column;
  border: 4px solid #2D2D2D;

  width: fit-content !important;
  max-width: 566px;

  background-color: white;

  justify-self: center;
}

.profile-row {
  display: flex;
  width: fit-content;
}

.no-puzzle-header {
  font-size: 20px;
  width: 500px;
}

.mobile-no-puzzle-header {
  font-size: 20px;
  margin-left: 28px;
  margin-right: 25px;
}

.color-container {
  align-items: center;
  margin-top: 20px;
  margin-left: 60px;
  display: flex;
  gap: 20px;
}

.color-set-cell {
  height: 60px;
  width: 60px;
  justify-content: center;
  align-items: center;
  border: 3px solid #2D2D2D;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  caret-color: transparent;   
  gap: 20px;
}

.mobile-profile-header {
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}

.mobile-profile-button {
  background: none;
  border: none;
  color: var(--color-normal);
  font: inherit;
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
  margin-top: 20px;
  margin-left: 15px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 35px;
  align-items: center;
  align-self: center;
  margin-right: 10px;
  /* hidden for now, will likely be settings */
  opacity: 0%;
}

.mobile-profile-back-button {
  background: none;
  border: none;
  color: var(--color-normal);
  font: inherit;
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
  margin-top: 20px;
  margin-right: 15px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 35px;
  align-items: center;
  align-self: center;
  margin-left: 10px;
}

.mobile-profile-header h1 {
  margin: 0px;
  padding: 0px;
  margin-top: 20px;
  font-size: 36px;
  font-style: italic;
  font-family: 'Inter', sans-serif;
  max-width: 230px;           
  overflow-x: auto;       
  white-space: nowrap;   
  flex-shrink: 0; 
  min-width: 0;  
}

.color-slider {
  display: flex;
  margin-right: 30px;
}

.arrow-button {
  background: none;
  border: none;
  color: #2D2D2D;
  font: inherit;
  cursor: pointer;
  display: flex;
  transition: background-color 0.3s, color 0.3s;
  font-size: 25px;
  align-items: center;
  align-self: center;
  padding: 0px;
}

.mobile-traits-and-color {
  display: flex;
  justify-content: space-between;
}

.mobile-profile-details {
  margin-left: 25px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr; 
  gap: 13px;
  font-family: 'Inter', sans-serif;
}

.profile-username {
  display: flex;
  flex-direction: column; 
  font-size: 40px;
  margin-bottom: 40px;
  max-width: 100%;
}

.mobile-puzzle-details {
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  justify-self: center;
  align-items: center;
  align-self: center;
}

.mobile-puzzles-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr); 
  gap: 10px; 
}

.mobile-puzzles-list ul {
  list-style: none;
  padding: 0;
  display: grid;
  margin: 0px;
  grid-template-columns: 1fr; 
}

.mobile-puzzles-list li {
  list-style-type: none;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-puzzles-list li strong {
  margin-bottom: 10px;
  text-align: center;
}

.mobile-trait-header {
  margin-top: 23px;
  margin-bottom: 5px;
  margin-left: 25px;
  font-size: 30px;
  font-weight: bold;
  margin-right: auto
;}

.friends-section {
  font-size: 22px;
  margin-left: 25px;
  margin-top: 20px;
  display: flex !important;
  flex-direction: row;
}

.requests {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
}

.search-friend-button {
  font-weight: bold;
  font-style: italic;
  border: none;
  margin-top: 10px;
  width: 120px;
  height: 30px;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  color: white;
  background-color: #2D2D2D;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-friend-button:hover {
  border: none;
  background-color: var(--color-dark);
}

.input-friend {
  height: 30px;
  width: 200px;
  padding: 10px;
  font-size: 15px;
  border: 1px solid #ccc;
}

.input-friend:hover {
  background-color: #f0f0f0;
}

.input-friend:focus {
  border-color: var(--color-dark);
  outline: none;
}

.mobile-input-friend {
  height: 30px;
  width: 228px;
  padding: 10px;
  font-size: 15px;
  border: 1px solid #ccc;
}

.mobile-input-friend:hover {
  background-color: #f0f0f0;
}

.mobile-input-friend:focus {
  border-color: var(--color-dark);
  outline: none;
}

.profile-popup {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  position: fixed;
  width: 400px; 
  height: 600px; 

  display: flex;
  flex-direction: column; 
  align-items: left; 

  color: rgb(46, 46, 46); 
  background-color: rgb(255, 255, 255);
  border: 4px solid #2D2D2D;
  z-index: 10; 
}

.mobile-profile-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  width: 300px; 
  height: 600px; 
  display: flex;
  flex-direction: column; 
  align-items: flex-start; /* use flex-start instead of left */
  color: rgb(46, 46, 46); 
  background-color: rgb(255, 255, 255);
  border: 4px solid #2D2D2D;
  z-index: 99; /* Very high so it appears on top */
  font-family: 'Inter', sans-serif;
}


.close-results-button {
  background: none;
  border: none;
  color: var(--color-normal);
  font: inherit; 
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
  font-size: 30px;
  align-items: center;
  align-self: center;
  margin-right: 10px;
  margin-top: 4px;
}

.close-results-button:hover {
  border: none;
  color: var(--color-dark);
}

.popup-header {
  margin-top: 10px;
  display: flex;
  margin-left: 23px;
}

.result-item {
  display: flex;
  align-items: center;
  list-style: none;
}

.result-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 35px;
  max-height: 490px;  /* Adjust as needed */
  overflow-y: auto;
}

.mobile-result-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 35px;
  height: 500px;  /* Adjust as needed */
  width: 244px;
  overflow-y: auto;
}

.add-friend-button {
  background: none;
  border: none;
  color: var(--color-normal);
  font: inherit; 
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
  font-size: 30px;
  align-items: center;
  align-self: center;
  margin-right: 10px;
  margin-top: 4px;
  margin-left: 5px;
}

.add-friend-button:hover {
  border: none;
  color: var(--color-dark);
}

.other-friend-button {
  background: none;
  border: none;
  color: var(--color-normal);
  font: inherit; 
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
  font-size: 30px;
  align-items: center;
  align-self: center;
  margin-right: 10px;
  margin-top: 4px;
  margin-left: 10px;
}

.other-friend-button:hover {
  border: none;
  color: var(--color-dark);
}

.accept-friend-button {
  background: none;
  border: none;
  color: var(--color-normal);
  font: inherit; 
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
  font-size: 30px;
  align-items: center;
  align-self: center;
  margin-top: 4px;
}

.accept-friend-button:hover {
  border: none;
  color: var(--color-dark);
}

.no-users {
  margin-left: 30px;
}

.req-button {
  font-weight: bold;
  font-style: italic;
  border: none;
  margin-top: 10px;
  width: 120px;
  height: 30px;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  color: white;
  background-color: #2D2D2D;
  cursor: pointer;
  transition: background-color 0.3s;
}

.req-button:hover {
  border: none;
  background-color: var(--color-dark);
}

.friend-search {
  display: flex;
  flex-direction: column;
}

.request-buttons {
  display: flex;
}

.request-username {
  margin-left: 20px;
  margin-right: 10px;
}

.pro-txt {
  margin-top: 0px;
  margin-bottom: 0px;
  font-style: italic;
  font-family: 'Inter', sans-serif;
}

.mobile-full-info-container {
  display: flex;
  flex-direction: column;
}

.requests-mobile {
  font-family: 'Inter', sans-serif;
}

.friends-horiz {
  display: flex;
  flex-direction: row;
  gap: 10px;
}