.recent-games-container {
  max-width: 900px;
  margin-left: 60px;
  margin-top: 10px;
  padding: 20px;
  text-align: left;
  font-family: 'Inter', sans-serif;
}

.recent-games-container h1 {
  font-size: 50px;
  width: 800px;
  margin-bottom: 20px;
  margin-left: 20px;
  font-style: italic;
  white-space: nowrap;
}
  
.recent-games-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 15px 80px;
}

.recent-games-list li {
  list-style-type: none;
  padding: 10px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.recent-games-list ul {
  list-style: none;
  padding: 0;
  display: grid;
  margin: 0px;
  grid-template-columns: repeat(3, 1fr); 
  gap: 4px 30px; /* vertical, horizontal spacing. respectively */
}

.no-recent-games {
  margin-top: 20px;
  margin-left: 30px;
  font-style: italic;
}

.back-recent-games-header {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center; 
  margin-bottom: 5px;
  margin-left: 60px;
}

.recent-game-inner-container {
  display: flex;
  transform-origin: top left;
}

.recent-game-attributes {
  display: column;
  margin-left: 20px;
  width: fit-content
}

.recent-games-list strong {
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: bold;
  font-style: italic;
}

.rg-attribute-header {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: bold;
  white-space: nowrap;
  width: fit-content;
  font-style: italic;
}

.rg-timer {
  font-size: 20px;
  font-style: italic;
}

.rg-attribute {
  margin-bottom: 20px;
}

.milliseconds {
  font-weight: normal;
  margin-left:2px;
  font-size: 10px;
  color: #666;
  font-style: italic;
}

.rg-icon {
  background: none;
  border: none;
  color: var(--color-dark);
  font: inherit; 
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
  font-size: 40px;
  align-items: center;
  align-self: center;

  padding: 0px;
  margin: 0px;
}

.mobile-rg-header {
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.mobile-rg-button {
  background: none;
  border: none;
  color: var(--color-normal);
  font: inherit;
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
  margin-top: 20px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 35px;
  align-items: center;
  align-self: center;
  margin-right: 10px;

  /* hidden for now */
  opacity: 0%;
}

.mobile-rg-back-button {
  background: none;
  border: none;
  color: var(--color-normal);
  font: inherit;
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
  margin-top: 20px;
  margin-right: 15px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 35px;
  align-items: center;
  align-self: center;
  margin-left: 10px;
}

.mobile-recent-games-container {
  max-width: 900px;
  max-height: 2000px;
  padding: 0px;
  text-align: left;
  height: fit-content;
  font-family: 'Inter', sans-serif;
}

.mobile-rg-back-button {
    background: none;
    border: none;
    color: var(--color-normal);
    font: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin-right: 15px;
    margin-top: 20px;
    margin-left: 10px;
    transition: background-color 0.3s, color 0.3s;
    font-size: 35px;
    align-items: center;
    align-self: center;
}

.mobile-rg-header h1 {
  margin: 0px;
  padding: 0px;
  margin-top: 20px;
  font-size: 30px;
  font-style: italic;
  font-family: 'Inter', sans-serif;
}

.mobile-recent-games-list {
  display: grid;
  grid-template-columns: 1fr; 
  gap: 15px 80px;
  padding-left: 20px;
  transform-origin: top left;
}

.mobile-recent-games-list li {
  list-style-type: none;
  padding: 10px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 0px;
  padding-top: 0px;
}

.mobile-recent-games-list ul {
  list-style: none;
  padding: 0;
  display: grid;
  margin: 0px;
  grid-template-columns: repeat(3, 1fr); 
  gap: 4px 30px; /* vertical, horizontal spacing. respectively */
}

@media (min-width: 421px) {
}

@media (max-width: 720px) {
  .mobile-recent-games-list {
    transform: scale(0.68);
    margin-top:8px;
  }
}

@media (max-width: 420px) {
  .mobile-recent-games-list {
    transform: scale(0.63);
    margin-top:8px;
  }
}

@media (max-width: 400px) {
}

@media (max-width: 380px) {
}

@media (max-width: 300px) {
}