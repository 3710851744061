.lb-header-container {
    display: flex;
    margin-left: 64px;
    margin-top: 62px;
    font-size: 50px;
    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-weight: bold;
}

.lb-back-button {
    margin-top: 10px;
    margin-right: 25px;
    background: none;
    border: none;
    color: var(--color-normal);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 35px;
}

.lb-back-button:hover {
    color: var(--color-dark);
}

.lb-table-container {
    margin-left: 110px;
    margin-top: 22px;
    font-size: 24px;
    list-style: none;
    display: column;
    border: 3px solid #2d2d2d;
    width: fit-content;
}

.mobile-lb-table-container {
    margin-top: 20px;
    font-size: 24px;
    list-style: none;
    display: column;
    border-top: 3px solid #2d2d2d;
    width: 100%;
    border-bottom: none;
    height: 100%;
    background-color: #efefef;
}

.mobile-leaderboard-container {
    height: 100vh;
    overflow: hidden;
}

.lb-entry {
    display: flex;
    margin-bottom: 0px;
    padding: 12px;
    background-color: #efefef;
    font-family: 'Inter', sans-serif;
    font-size: 16px;

    border-bottom: 2px solid #2d2d2d;
    align-items: center;
}

.lb-entry:last-child {
    border-bottom: none; 
}

.mobile-lb-entry {
    display: flex;
    margin-bottom: 0px;
    padding: 12px;
    background-color: #efefef;
    font-family: 'Inter', sans-serif;
    font-size: 16px;

    border-bottom: 2px solid #2d2d2d;
    align-items: center;
}

.mobile-lb-entry:last-child {
    border-bottom: none; 
}

.lb-table-header {
    display: flex;
    padding: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom: 3px solid #2d2d2d;
    background-color: var(--color-dark);

    font-size: 16px;
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-style: italic;
}

.mobile-lb-table-header {
    display: flex;
    padding: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom: 3px solid #2d2d2d;
    background-color: var(--color-dark);

    font-size: 16px;
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-style: italic;
}

.sr-header {
    margin-right: 30px;
}

.rank-header {
    margin-right: 60px;
}

.username-header {
    margin-right: 130px;
}

.mobile-sr-header {
    margin-right: 30px;
}

.mobile-rank-header {
    margin-right: 70px;
}

.mobile-username-header {
    margin-right: 143px;
}

.entry-username {
    width: 220px;
    margin-left: 38px;
}

.entry-rank-number {
    margin-left: 8px;
    font-size: 20px;
    width: 65px;
    font-weight: bold;
    font-style: italic;
}

.under-construction {
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 30px;
}