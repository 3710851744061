/* Container for the entire dashboard */
.dashboard-container {
}

/* Desktop styles */
.dashboard-container .mobile-only {
  display: none;
}

.dashboard-container .desktop-only {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Inter', sans-serif;
  margin-left: 40px;
  margin-top: 10px;
}

/* Styles for mobile devices */
@media (max-width: 768px) {
  .dashboard-container .mobile-only {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    height: 100vh;
  }

  .dashboard-container .desktop-only {
    display: none;
  }
}

@media (min-width: 769px) {
  .dashboard-container .desktop-only {
    display: flex;
  }

  .dashboard-container .mobile-only {
    display: none;
  }
}
  
.puzzle-grid {
  display: flex;
  flex-direction: column; 
  list-style: none;
  padding: 0;
  max-height: 584px;
  overflow-y: auto;
}

.ultimate-header-container {
  display: flex;
  flex-direction: column;
}

.ultimate-content-container {
  margin-left: 50px;
  display: flex;
}

.puzzle-list-header {
  display: flex;
  width: 602px;
  padding: 10px 15px;
  background-color: var(--color-dark);
  color: white;
  font-weight: bold;
  font-style: italic;
  border-bottom: 3.5px solid #2D2D2D;
  padding-bottom: 5px;
}

.play-or-rating {
  margin-left: 74px;
}

.name-header {
  margin-right: 220px;
}
  
  /* Hover effect for each puzzle item */
  .puzzle-item:hover {
    background-color: var(--color-light);
    font-weight: bold;
  }
  
  /* Button to play a random puzzle */
  .dashboard-random-button {
    background: none;
    border: none;
    color: var(--color-normal);
    font: inherit;
    cursor: pointer;
    display: flex;
    padding: 5px 10px;
    margin-top: 15px;
    margin-left: 25px;
    transition: background-color 0.3s, color 0.3s;
    font-size: 35px;
    align-items: center;
    align-self: center;
}

.dashboard-random-button:hover {
    color: var(--color-dark)
}
  
.dashboard-header-container {
    display: flex;
    width: 900px;
}
  
.dashboard-back-button {
    background: none;
    border: none;
    color: var(--color-normal);
    font: inherit;
    cursor: pointer;
    display: flex;
    padding: 5px 10px;
    margin-top: 15px;
    transition: background-color 0.3s, color 0.3s;
    font-size: 35px;
    align-items: center;
    align-self: center;
}

.dashboard-back-button:hover {
    color: var(--color-dark);
}

.dashboard-header-container h1 {
    font-size: 50px;
    margin-bottom: 20px;
    margin-left: 20px;
    font-style: italic;
}

.puzzle-item {
  display: flex;
  width: 100%;
  padding: 10px 15px;
  background-color: #efefef;
  border-bottom: 2px solid #2D2D2D;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.puzzle-container {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.puzzle-container:last-child .puzzle-item {
  border-bottom: none; 
}

.dashboard-puzzles-subcontain {
  display: flex;
  gap:0px;
  flex-direction: column;
  border: 3.5px solid #2D2D2D
}

.puzzle-title {
  width: 385px;
}

.puzzle-plays {
  width: 115px;
  margin-left: 10px;
}

.puzzle-rating {
  width: 20px;
  margin-left: 10px;
}

.puzzle-details-container {
  display: flex;

  padding-left: 40px;

  margin-left: 20px;
  margin-right: 60px;

  width: 600px;
  height: 600px;

  font-family: 'Inter', sans-serif;
}

.puzzle-details-container h2 {
  max-width: 570px;
  max-height: 200px;
  margin: 0px;
  font-size: 40px;
  margin-bottom: 20px;
}

.puzzle-details-container p {
  margin: 0px;
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.dash-play-button {
  font-weight: bold;
  font-style: italic;
  border: none;
  margin-top: 10px;
  width: 300px;
  height: 70px;
  font-size: 26px;
  font-family: 'Inter', sans-serif;
  color: white;
  background-color: #2D2D2D;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dash-play-button:hover {
  border: none;
  background-color: var(--color-dark);
}

.puzzle-attr {
  font-weight: bold;
}

.dashboard-item {
  display: flex;
  flex-direction: column; 
  font-size: 24px;
}

.dash-instruction-header {
  margin-right: 50px;
  margin-left: 15px;
  padding-bottom: 90px;

  font-size: 36px;

  align-self: center;

  font-style: italic;
  font-weight: bold;
}

.horizontal-item-container {
  display: flex;
}

.plays {
  font-weight: bold;
  margin-right: 45px;
}

.comment-header {
  margin-top: 5px;
  padding: 5px;
  padding-left: 15px;
  font-weight: bold;
  font-size: 16px;
  font-style: italic;
  background-color: var(--color-dark);
  color: white;
  border: 3.5px solid #2D2D2D;
}

.ultimate-comment-container textarea {
  font-family: 'Inter', sans-serif;
  width: 100%;
  height: 80%;
  min-width: 100%;       
  box-sizing: border-box;
  border-radius: 0;
  resize: vertical;      
  padding: 10px;
  outline: none; 
  border: 3.5px solid #2D2D2D;
  border-top: 0px;
  transition: border 0.3s, box-shadow 0.3s; 
  min-height: 50px;
}

.ultimate-comment-container textarea:focus {
  border: 3.5px solid #2D2D2D;
  border-top: 0px;
  box-shadow: inset 0 0 0 3px var(--color-normal); 
  outline: none; 
}

.comment-container {
  width: 566px;
  border-top: 0px;
  max-height: 190px; 
  overflow: hidden;
  overflow-y: auto; 
}

.comment-container-bordered {
  border: 3.5px solid #2D2D2D;
  border-top: 0px;
  max-height: 135px;
}

.comment-container-empty {
  border: 0px;
}

.comment-container ul {
  padding: 0px;
  margin: 0px;

  overflow-y: auto; 

  background-color: #efefef;
  list-style-type: none;
}

.comment-container p {
  margin: 0px;
  font-size: 14px;
  word-break: break-all !important; 
  overflow-wrap: break-word; 
}

.comment-container li {
  transition: background-color 0.3s, color 0.3s;
  margin: 0px;
  padding: 10px;
  border-bottom: 2px solid #2D2D2D;
}

.comment-container li:last-child {
  border: 0px;
}

.ultimate-comment-container form {
  display: flex;
  flex-direction: column;
  border: none;
}

.submit {
  padding: 8px;
  border: 3.5px solid #2D2D2D;
  border-top: 0px;
  width: 100%;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  color: white;
  background-color: #2D2D2D;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit:hover {
  border: 3.5px solid #2D2D2D;
  border-top: 0px;
  background-color: var(--color-dark);
}

.delete-button {
  background: none;
  border: none;
  color: var(--color-dark);
  font: inherit; 
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-left: auto;
  transition: background-color 0.3s, color 0.3s;
  font-size: 20px;
  align-items: center;
  align-self: center;
}

.delete-button:hover {
  color: #2D2D2D;
}

.comment-inner-header-and-content {
  display: flex;
  flex-direction: column;
}

.comment-details-container {
  display: flex;
}

.username-date {
  display: flex;
}

.username-date strong {
  margin-right: 4px;
}

.comments-list {
  overflow-y: auto; 
  flex-grow: 1; 
  margin: 10px 0; 
}

.selected-puzzle {
  background-color: var(--color-light);
  font-weight: bold;
  font-style: italic;
}

.played-icon {
  margin-left: 8px; 
  vertical-align: middle;
}

.played-icon i {
  color: var(--color-dark);
  font-size: 18px; 
}

/* MOBILE */
.mobile-play-header {
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}

.mobile-play-header h1 {
  margin: 0px;
  padding: 0px;
  margin-top: 20px;
  font-size: 36px;
  font-style: italic;
}

.mobile-dashboard-random-button {
  background: none;
  border: none;
  color: var(--color-normal);
  font: inherit;
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
  margin-top: 20px;
  margin-left: 15px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 35px;
  align-items: center;
  align-self: center;
  margin-right: 10px;
}

.mobile-dashboard-random-button:hover {
    color: var(--color-dark)
}

.mobile-dashboard-back-button {
  background: none;
  border: none;
  color: var(--color-normal);
  font: inherit;
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
  margin-top: 20px;
  margin-right: 15px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 35px;
  align-items: center;
  align-self: center;
  margin-left: 10px;
}

.mobile-dashboard-back-button:hover {
  color: var(--color-dark);
}

.mobile-ultimate-content-container {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #efefef;
  border: 9px solid var(--color-dark);
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 7px;
  flex-grow: 1;
  
  height: fit-content;
  max-height: 70%;
}

.mobile-puzzle-list-header {
  display: flex;
  width: 93%;
  padding: 10px 15px;
  background-color: var(--color-dark);
  justify-content: space-between;
  color: white;
  font-weight: bold;
  font-style: italic;
  font-size: 20px;
  border-bottom: 4px solid var(--color-dark);
}

.mobile-name-header {
  margin-left: 10px;
  font-size: 16px;
}

.mobile-rating {
  margin-right: 10px;
  font-size: 16px;
}

.mobile-puzzle-grid {
  display: flex;
  flex-direction: column; 
  list-style: none;
  padding: 0;
  max-height: 100%;
  font-size: 13px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.mobile-puzzle-rating {
  font-size: 18px;
}

.mobile-puzzle-item {
  display: flex;
  height: 20px;
  width: 93%;
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-bottom: 2px solid #2D2D2D;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.mobile-puzzle-item:hover {
  background-color: var(--color-light);
  font-weight: bold;
}

.mobile-dash-play-button {
  font-weight: bold;
  font-style: italic;
  width: 20%;
  border: none;
  font-size: 20px;
  font-weight: normal;
  margin-right: 20px;
  padding: 10px 10px;
  padding-right: 60px;
  font-family: 'Inter', sans-serif;
  background-color: #2D2D2D;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.mobile-play-container {
  padding-left: 15px;
  display: flex;
  height: 60px;
  width: 100%;
  align-items: center;
  font-weight: bold;
  font-style: italic;
  gap: 0px;
  justify-content: space-between;
  background-color: var(--color-light);
  border-bottom: 2px solid #2D2D2D;
  overflow: hidden; 
  transition: height 0.3s cubic-bezier(0.25, 0.1, 0.25, 1); 
}

/* Mobile container takes full viewport height and prevents scrolling */
.mobile-only-novscroll {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}