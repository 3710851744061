.custom-keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  padding-bottom: 20px;
  padding-top: 5px;
  width: 100%;
  background-color: var(--color-light);
  

  /* Positioning at the bottom of the screen */
  position: fixed;
  bottom: 0;
  left: 0;
}

  
  .keyboard-row {
    display: flex;
    margin: 1px 0;
  }
  
  .keyboard-key {
    background-color: #fff;
    border: 1px solid var(--color-normal);
    color: #2D2D2D;
    width: 36px;
    height: 45px;
    padding: 7px 7px;
    margin: 1.3px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .keyboard-key:active {
    background-color: #d1d1d1; 
  }
  

  .middle-key {
    flex: 1;
    width: 36px;
    font-size: 18px;
  }
  
  .enter-key {
    background-color: var(--color-dark);
    border:none;
    color: white;
    flex: 1;
    width: 40px;
    font-size: 22px;
    padding: 4px;
    padding-bottom: 0px;
  }

  .long-enter-key {
    background-color: var(--color-dark);
    border:none;
    color: white;
    flex: 1;
    width: 80px;
    font-size: 22px;
    padding: 4px;
    padding-bottom: 0px;
  }

  .space-bar-key {
    background-color: var(--color-dark);
    border:none;
    color: white;
    flex: 1;
    width: 300px;
    max-height: 40px;
    font-size: 35px;
    padding: 4px;
    padding-bottom: 0px;
  }

  .special-char-key {
    background-color: var(--color-dark);
    border:none;
    color: white;
    flex: 1;
    max-width: 80px;
    max-height: 40px;
    font-size: 22px;
    padding: 4px;
    padding-bottom: 0px;
  }

  .special-char-text {
    padding-bottom: 2px;
  }

  .return-key {
    background-color: var(--color-dark);
    border:none;
    color: white;
    flex: 1;
    max-width: 80px;
    max-height: 40px;
    font-size: 28px;
    padding: 4px;
    padding-bottom: 0px;
  }

  .rotate-key {
    background-color: var(--color-dark);
    border:none;
    color: white;
    flex: 1;
    width: 40px;
    font-size: 22px;
    padding: 2px;
    padding-bottom: 0px;
  }

  .dash-key {
    background-color: #2D2D2D;
    border:none;
    color: white;
    flex: 1;
    max-width: 80px;
    max-height: 40px;
    font-size: 22px;
    padding: 2px;
    padding-bottom: 0px;
  }

  .bottom-key {
    width: 34px;
  }

/* create */
@media (min-width: 421px) {
  .custom-keyboard.create-mode {
      top: 500px;
  }
}

@media (max-width: 420px) {
  .custom-keyboard.create-mode {
      top: 500px;
  }
}

@media (max-width: 400px) {
  .custom-keyboard.create-mode {
      top: 468px;
  }
}

@media (max-width: 380px) {
  .custom-keyboard.create-mode {
      top: 398;
  }
}

/* play */
@media (min-width: 421px) {
  .custom-keyboard.play-mode {
      top: 520px;
  }
}

@media (max-width: 420px) {
  .custom-keyboard.play-mode {
      top: 521px;
  }
}

@media (max-width: 410px) {
  .custom-keyboard.play-mode {
      top: 522px;
  }
}

@media (max-width: 400px) {
  .custom-keyboard.play-mode {
      top: 510px;
  }
}

@media (max-width: 380px) {
  .custom-keyboard.play-mode {
      top: 460px;
  }
}